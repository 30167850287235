import Track from "../../models/Track.type";
import types from "../Types";

type UploadTrackPopupReducerAction = {
    type: string;
    payload: boolean | number | Track | undefined;
};

type UploadTrackPopupReducerState = {
    isTrackEditShown: boolean;
    isTrackUploadShown: boolean;
    trackToEdit: Track | undefined;
    step: number;
};

const initialState = {
    isTrackEditShown: false as boolean,
    isTrackUploadShown: false as boolean,
    trackToEdit: undefined,
    step: 1,
};

export const uploadTrackPopupReducers = (
    state: UploadTrackPopupReducerState = initialState,
    action: UploadTrackPopupReducerAction
) => {
    switch (action.type) {
        case types.TYPE_SET_UPLOAD_TRACK_SHOW_POPUP:
            return {
                ...state,
                isTrackUploadShown: (action.payload as boolean) || false,
            };
        case types.TYPE_EDIT_TRACK_SHOW_POPUP:
            return {
                ...state,
                isTrackEditShown: (action.payload as boolean) || false,
            };
        case types.TYPE_EDIT_TRACK_TRACK:
            return {
                ...state,
                trackToEdit: (action.payload as Track) || undefined,
            };
        case types.TYPE_SET_UPLOAD_TRACK_STEP:
            return {
                ...state,
                step: (action.payload as number) || 0,
            };
        default:
            return state;
    }
};
