import { useState } from "react";

export function useLocalStorage<T>(key: string, initialValue?: T) {
    const [storedValue, setStoredValue] = useState<T | undefined | null>(() => {
        if (initialValue === undefined || initialValue === null) {
            let _value: any;
            try {
                _value = JSON.parse(window.localStorage.getItem(key) || "");
            } catch (e) {
                _value = initialValue;
            }
            return _value;
        } else {
            return initialValue;
        }
    });

    const setValue = (value: any) => {
        window.localStorage.setItem(key, JSON.stringify(value || ""));
        if (value === undefined) {
            window.localStorage.removeItem(key);
        }
        setStoredValue(value);
    };

    return [storedValue, setValue];
}
