import types from "../Types";
import Onboarding from "../../models/Onboarding.type";
import PrefItem from "../../models/PrefItem";

type OnboardingReducerAction = {
    type: string;
    payload: Onboarding | PrefItem[] | PrefItem | undefined;
};
type OnboardingReducerState = {
    onboarding: Onboarding | undefined;
    genres: PrefItem[];
    artists: PrefItem[];
    artist_types: PrefItem[];
};
const initialState: OnboardingReducerState = {
    onboarding: {} as Onboarding,
    genres: [] as PrefItem[],
    artists: [] as PrefItem[],
    artist_types: [] as PrefItem[],
};

export const onboardingReducers = (
    state: OnboardingReducerState = initialState,
    action: OnboardingReducerAction
) => {
    switch (action.type) {
        case types.TYPE_SET_ONBOARDING:
            return {
                ...state,
                onboarding: action.payload,
            };
        case types.TYPE_SET_ONBOARDING_TYPES_GENRES:
            return {
                ...state,
                genres: action.payload,
            };
        case types.TYPE_SET_ONBOARDING_TYPES_ARTISTS:
            return {
                ...state,
                artists: action.payload,
            };
        case types.TYPE_SET_ONBOARDING_TYPES_ARTIST_TYPES:
            return {
                ...state,
                artist_types: action.payload,
            };
        default:
            return state;
    }
};
