import Track from "../../models/Track.type";
import types from "../Types";

type ReducerAction = {
    type: string;
    payload: string | Track | Track[] | undefined;
};
type ReducerState = {
    searchParam: string;
    searchRoute: string;
    searchCurrentTrack: Track;
    searchPlaylist: Track[];
};
const initialState: ReducerState = {
    searchParam: "",
    searchRoute: "",
    searchCurrentTrack: {} as Track,
    searchPlaylist: [] as Track[],
};

export const searchReducers = (
    state: ReducerState = initialState,
    action: ReducerAction
) => {
    switch (action.type) {
        case types.TYPE_SEARCH_PARAM:
            return {
                ...state,
                searchParam: action.payload as string,
            };
        case types.TYPE_SEARCH_PLAYLIST:
            return {
                ...state,
                searchPlaylist: action.payload as Track[],
            };
        case types.TYPE_SEARCH_PLAYLIST_CONCAT:
            return {
                ...state,
                searchPlaylist: [
                    ...state.searchPlaylist.concat(action.payload as Track[]),
                ],
            };
        case types.TYPE_SEARCH_SET_ROUTE:
            return {
                ...state,
                searchRoute: action.payload as string,
            };
        case types.TYPE_SEARCH_PREV: {
            if (state.searchPlaylist.indexOf(state.searchCurrentTrack) > 0) {
                return {
                    ...state,
                    searchCurrentTrack: state.searchPlaylist[
                        state.searchPlaylist.indexOf(state.searchCurrentTrack) -
                            1
                    ] as Track,
                };
            } else {
                return state;
            }
        }
        case types.TYPE_SEARCH_NEXT:
		
            if (
                state.searchPlaylist.indexOf(state.searchCurrentTrack) + 1 !==
                state.searchPlaylist.length
            ) {
                return {
                    ...state,
                    searchCurrentTrack: state.searchPlaylist[
                        state.searchPlaylist.indexOf(state.searchCurrentTrack) +
                            1
                    ] as Track,
                };
            } else {
                return state;
            }
        case types.TYPE_SET_SEARCH_CURRENT_TRACK:
            return {
                ...state,
                searchCurrentTrack: action.payload as Track,
            };
        default:
            return state;
    }
};
