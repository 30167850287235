import { combineReducers } from "redux";
import { audioPlayerReducer } from "./AudioPlayer.reducer";
import { collaborationPopupReducers } from "./CollaborationPopup.reducer";
import { onboardingReducers } from "./Onboarding.reducer";
import { toastReducers } from "./Toast.reducer";
import { userReducers } from "./User.reducer";
import { uploadTrackPopupReducers } from "./UploadTrackPopup.reducer";
import { searchReducers } from "./Search.reducer";
import { collaborationReducers } from "./Collaboration.reducer";
import collaborations from "./collaborations";
import { inviteReducers } from "./Invites.reducer";

const rootReducers = combineReducers({
    user: userReducers,
    onboarding: onboardingReducers,
    toasts: toastReducers,
    audioPlayer: audioPlayerReducer,
    collaborationPopup: collaborationPopupReducers,
    uploadTrackPopup: uploadTrackPopupReducers,
    invites: inviteReducers,
    search: searchReducers,
    collaborations: collaborations,
    collaboration: collaborationReducers,
});

export default rootReducers;
