import Track from "../../models/Track.type";
import types from "../Types";

type ReducerAction = {
    type: string;
    payload: boolean | number | Track | undefined;
};

type ReducerState = {
    isInvitePopupShown: boolean;
    step: number;
};

const initialState = {
    isInvitePopupShown: false as boolean,
    step: 1,
};

export const inviteReducers = (
    state: ReducerState = initialState,
    action: ReducerAction
) => {
    switch (action.type) {
        case types.TYPE_SHOW_INVITES_POPUP:
            return {
                ...state,
                isInvitePopupShown: (action.payload as boolean) || false,
            };
        case types.TYPE_INVITES_SET_STEP:
            return {
                ...state,
                step: (action.payload as number) || 1,
            };
        default:
            return state;
    }
};
