import { ReactComponent as LoadingImage } from "../assets/images/loading_image.svg";

type Props = {
    message?: string;
};

const LoadingLargeView = ({ message }: Props) => {
    return (
        <div className="select-none text-center">
            <LoadingImage className="animate-spin" />
            {message && <p className="mt-12 text-sm select-none">{message}</p>}
        </div>
    );
};

export default LoadingLargeView;
