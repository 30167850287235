import Collaboration from "../../models/Collaboration.type";
import types from "../Types";

type countAndPage = {
    page: number;
    count: number;
};

type CollaborationReducerAction = {
    type: string;
    payload: number | null | Collaboration[] | countAndPage;
};

type CollaborationReducerState = {
    collaborationReceived: Collaboration[];
    collaborationReceivedPage: number;

    collaborationSent: Collaboration[];
    collaborationSentPage: number;

    requestReceivedCountPage: number;
    requestReceivedCount: number;

    collaborationDataUpdated: number;
    collaborationDMs: Collaboration[];
};

const initialState = {
    collaborationReceived: [],
    collaborationReceivedPage: 1,

    collaborationSent: [],
    collaborationSentPage: 1,

    requestReceivedCountPage: 1,
    requestReceivedCount: 0,

    collaborationDataUpdated: 0,
    collaborationDMs: [],
};

export const collaborationReducers = (
    state: CollaborationReducerState = initialState,
    action: CollaborationReducerAction
) => {
    switch (action.type) {
        case types.TYPE_SET_DM_REQUESTS:
            return {
                ...state,
                collaborationDMs: action.payload as Collaboration[],
            };
        case types.TYPE_CONCAT_DM_REQUESTS:
            return {
                ...state,
                collaborationDMs: [
                    ...state.collaborationDMs.concat(
                        action.payload as Collaboration[]
                    ),
                ],
            };

        //LIST RECEIVED
        case types.TYPE_SET_COLLAB_RECEIVED_LIST:
            return {
                ...state,
                collaborationReceived: action.payload as Collaboration[],
            };
        case types.TYPE_CONCAT_COLLAB_RECEIVED_LIST:
            return {
                ...state,
                collaborationReceived: [
                    ...state.collaborationReceived.concat(
                        action.payload as Collaboration[]
                    ),
                ],
            };
        case types.TYPE_SET_COLLAB_RECEIVED_LIST_PAGE:
            return {
                ...state,
                collaborationReceivedPage: (action.payload as number) || 1,
            };
        //LIST SENT
        case types.TYPE_SET_COLLAB_SENT_LIST:
            return {
                ...state,
                collaborationSent: action.payload as Collaboration[],
            };
        case types.TYPE_CONCAT_COLLAB_SENT_LIST:
            return {
                ...state,
                collaborationSent: [
                    ...state.collaborationSent.concat(
                        action.payload as Collaboration[]
                    ),
                ],
            };
        case types.TYPE_SET_COLLAB_SENT_LIST_PAGE:
            return {
                ...state,
                collaborationSentPage: (action.payload as number) || 1,
            };

        //COUNT
        case types.TYPE_SET_REQUEST_COUNT:
            return {
                ...state,
                requestReceivedCount:
                    ((action.payload as countAndPage).count as number) || 0,
                requestReceivedCountPage:
                    ((action.payload as countAndPage).page as number) || 1,
            };
        case types.TYPE_SET_REQUEST_COUNT_PAGE:
            return {
                ...state,
                requestReceivedCountPage: action.payload as number,
            };
        case types.TYPE_SET_REQUEST_COUNT_INCREMENT:
            return {
                ...state,
                requestReceivedCount: state.requestReceivedCount + 1,
            };
        case types.TYPE_SET_REQUEST_COUNT_REDUCE:
            return {
                ...state,
                requestReceivedCount:
                    state.requestReceivedCount - 1 < 0
                        ? 0
                        : state.requestReceivedCount - 1,
            };
        case types.TYPE_SET_UPDATE_COLLAB_DATA:
            return {
                ...state,
                collaborationDataUpdated: Math.random(),
                collaborationDMs: [],
                requestReceivedCountPage: 1,
                requestReceivedCount: 1,
            };
        default:
            return state;
    }
};
