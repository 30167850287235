import types from "../Types";
import Track from "../../models/Track.type";
import PrefItem from "../../models/PrefItem";

type AudioPlayerReducerAction = {
    type: string;
    payload: Track[] | Track | boolean | number | string | PrefItem;
};
type AudioPlayerReducerState = {
    playlist: Track[];
    playlistSource: string;
    currentTrack: Track;
    audioPlaying: boolean;
    isLyricsShown: boolean;
    isStoryShown: boolean;
    isCurrentTrackToggleChange: boolean;
    audioVolume: number;
    isLoopingSingleTrack: boolean;
    isFullTrackAvailable: boolean;
    actionEvent: string;
    filterOnPlaylist: PrefItem;
};
const initialState = {
    playlist: [] as Track[],
    playlistSource: "discover",
    currentTrack: {} as Track,
    audioPlaying: false,
    isLyricsShown: false,
    isStoryShown: false,
    isCurrentTrackToggleChange: false,
    isLoopingSingleTrack: true,
    isFullTrackAvailable: false,
    audioVolume: 1,
    actionEvent: "",
    filterOnPlaylist: {} as PrefItem,
};

export const audioPlayerReducer = (
    state: AudioPlayerReducerState = initialState,
    action: AudioPlayerReducerAction
) => {
    switch (action.type) {
        case types.TYPE_SET_PLAYLIST:
            return {
                ...state,
                playlist: [...(action.payload as Track[])],
            };
        case types.TYPE_SET_PLAYLIST_SOURCE:
            return {
                ...state,
                playlistSource: action.payload as string,
            };
        case types.TYPE_CONCAT_PLAYLIST:
            return {
                ...state,
                playlist: [...state.playlist.concat(action.payload as Track[])],
            };
        case types.TYPE_SET_LOOPING_TRACK:
            return {
                ...state,
                isLoopingSingleTrack: action.payload as boolean,
            };
        case types.TYPE_SET_FULL_TRACK_AVAILABLE:
            return {
                ...state,
                isFullTrackAvailable: action.payload as boolean,
            };
        case types.TYPE_SET_CURRENT_TRACK:
            return {
                ...state,
                currentTrack: action.payload as Track,
            };
        case types.TYPE_FILTER_ON_PLAYLIST:
            return {
                ...state,
                filterOnPlaylist: action.payload as PrefItem,
            };
        case types.TYPE_FIRE_ACTION:
            return {
                ...state,
                actionEvent: action.payload as string,
            };
        case types.TYPE_PREV_TRACK_IN_PLAYLIST: {
            const elementPos = state.playlist
                .map((x) => {
                    return x.id;
                })
                .indexOf(state.currentTrack.id);
            if (elementPos > 0) {
                return {
                    ...state,
                    currentTrack: state.playlist[elementPos - 1] as Track,
                    isCurrentTrackToggleChange:
                        !state.isCurrentTrackToggleChange,
                };
            } else {
                return state;
            }
        }
        case types.TYPE_NEXT_TRACK_IN_PLAYLIST: {
            const elementPos = state.playlist
                .map((x) => {
                    return x.id;
                })
                .indexOf(state.currentTrack.id);

            if (elementPos + 1 !== state.playlist.length) {
                return {
                    ...state,
                    currentTrack: state.playlist[elementPos + 1] as Track,
                    isCurrentTrackToggleChange:
                        !state.isCurrentTrackToggleChange,
                };
            } else {
                return state;
            }
        }
        case types.TYPE_SET_AUDIOPLAYING:
            return {
                ...state,
                audioPlaying: action.payload as boolean,
            };
        case types.TYPE_SET_LYRICS_POPUP:
            return {
                ...state,
                isLyricsShown: action.payload as boolean,
            };
        case types.TYPE_SET_STORY_POPUP:
            return {
                ...state,
                isStoryShown: action.payload as boolean,
            };
        case types.TYPE_SET_AUDIOVOLUME:
            return {
                ...state,
                audioVolume: action.payload as number,
            };
        default:
            return state;
    }
};
