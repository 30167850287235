import types from "../Types";
import Toast from "../../models/Toast.type";

type ToastReducerAction = {
    type: string;
    payload: Toast[] | Toast;
};
type ToastReducerState = {
    toasts: any[];
};
const initialState: ToastReducerState = {
    toasts: [] as Toast[],
};

export const toastReducers = (
    state: ToastReducerState = initialState,
    action: ToastReducerAction
) => {
    switch (action.type) {
        case types.TYPE_PUSH_TOAST:
            return {
                ...state,
                toasts: [
                    ...state.toasts,
                    {
                        ...action.payload,
                        createdAt:
                            new Date().getTime() +
                            "_" +
                            Math.random().toString(36).slice(-5),
                    },
                ],
            };
        case types.TYPE_REMOVE_TOAST:
            if (!Array.isArray(action.payload)) {
                const indexOf = state.toasts.indexOf(action.payload);
                const newToasts = [...state.toasts];
                newToasts.splice(indexOf, 1);
                return {
                    ...state,
                    toasts: newToasts,
                };
            } else {
                return state;
            }

        default:
            return state;
    }
};
