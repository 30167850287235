import React, { useState } from "react";

type AppContext = {
    trackingToken: string;
    trackingUserId: string;
    trackingSetToken: (trackingToken: string) => void;
    trackingSetUserId: (trackingUserId: string) => void;

    darkmode: boolean;
    setDarkmode: (darkmode: boolean) => void;
};

export const AppLifeContext = React.createContext<AppContext>({
    trackingToken: "",
    trackingUserId: "",
    trackingSetToken: () => {
        return {};
    },
    trackingSetUserId: () => {
        return {};
    },

    darkmode: false,
    setDarkmode: () => {
        return {};
    },
});

export const AppLifeContextProvider = (props: any) => {
    //Tracking ctx
    const trackingSetToken = (trackingToken: string) => {
        setState({ ...state, trackingToken: trackingToken });
    };

    const trackingSetUserId = (trackingUserId: string) => {
        setState({ ...state, trackingUserId: trackingUserId });
    };

    const setDarkmode = (darkmode: boolean) => {
        setState({ ...state, darkmode });
    };

    const initState: AppContext = {
        trackingToken: props.value?.trackingToken || "",
        trackingUserId: props.value?.trackingUserId || "",
        trackingSetToken: trackingSetToken,
        trackingSetUserId: trackingSetUserId,

        darkmode: props.value?.darkmode || false,
        setDarkmode: setDarkmode,
    };

    const [state, setState] = useState<AppContext>(initState);

    return (
        <AppLifeContext.Provider value={state}>
            {props.children}
        </AppLifeContext.Provider>
    );
};
