import { useEffect, useState } from "react";
import API from "../lib/API";
import { LoadingClass } from "../lib/LoadingClass";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/Store.hook";
import {
    useInputEle,
    INPUT_CHECKS,
    INPUT_TYPES,
} from "../hooks/useInputEle.hook";
import { isObjSet } from "../lib/IsObjSet";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import { ReactComponent as IconCheck } from "../assets/images/icon_check.svg";
import { useEventTracking } from "../lib/useEventTracking";
import types from "../store/Types";
import Toast from "../models/Toast.type";

const ForgotPw = () => {
    //
    const inputEmail = useInputEle(
        "",
        INPUT_TYPES.EMAIL,
        "Enter your email address",
        [INPUT_CHECKS.EMAIL, INPUT_CHECKS.INPUT_REQUIRED]
    );

    //
    const eventTracking = useEventTracking();

    //
    const [isLoadingEmailPw, setLoadingEmailPw] = useState(false);
    const [isForgotSuccess, setForgotSuccess] = useState(false);

    //
    const { user } = useAppSelector((state) => state.user);

    //
    const router = useHistory();
    const dispatch = useAppDispatch();

    useEffect(() => {
        eventTracking.onTrackPage("Forgot password");
    }, []);

    useEffect(() => {
        if (isObjSet(user)) {
            router.push("/onboarding/1");
        }
    }, [user, router]);

    const onForgotPw = (
        event:
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
            | React.FormEvent<HTMLFormElement>
    ) => {
        event.preventDefault();

        if (inputEmail.onErrorCheck() != "") {
            return;
        }

        eventTracking.onTrackEvent("Forgot password button");

        setLoadingEmailPw(true);

        API.onForgotPw(inputEmail.value)
            .then(() => {
                setLoadingEmailPw(false);
                setForgotSuccess(true);
            })
            .catch(() => {
                dispatch({
                    type: types.TYPE_PUSH_TOAST,
                    payload: {
                        msg: "We can’t find that email. Kanye, is that you?",
                        type: "Danger",
                    } as Toast,
                });
                setLoadingEmailPw(false);
            });
    };

    return (
        <div className="w-screen h-screen flex flex-row animate-fade-in">
            <Logo className="fixed left-4 top-4 w-32" />
            <div className="bg-white w-full md:w-4/12 flex min-h-screen justify-center items-center">
                <Logo className="w-0 h-0" />
                <div className="max-w-sm w-full h-auto flex flex-col text-center pr-3 pl-3">
                    {!isForgotSuccess && (
                        <>
                            <h1 className="text-xl mb-3 font-semibold">
                                Forgot your password?
                            </h1>

                            <p className="text-sm mb-10">
                                Even Kanye forgets his password sometimes. We
                                think. Maybe it’s just you.
                            </p>

                            <form onSubmit={(e) => onForgotPw(e)}>
                                {inputEmail.view}

                                <button
                                    className={
                                        "button-primary " +
                                        LoadingClass(isLoadingEmailPw)
                                    }
                                    onClick={(e) => onForgotPw(e)}
                                >
                                    Submit
                                </button>
                            </form>
                        </>
                    )}
                    {isForgotSuccess && (
                        <div className="animate-fade-in-down flex w-full flex-col items-center">
                            <IconCheck />
                            <h1 className="text-xl mb-3 font-semibold">
                                Success!
                            </h1>

                            <p className="text-sm mb-5">
                                Check your inbox - it might even have ended up
                                in your spam folder
                            </p>
                        </div>
                    )}

                    <p className="text-sm mt-5 text-custom-medium">
                        Got your memory back?
                    </p>
                    <p className="text-sm mt-0 text-custom-medium">
                        <u>
                            <b>
                                <Link to="/signin">Sign in</Link>
                            </b>
                        </u>
                    </p>
                </div>
            </div>
            <div className="bg-custom-hover w-8/12 bg-forgotpw-bg bg-no-repeat bg-cover hidden md:block"></div>
        </div>
    );
};

export default ForgotPw;
