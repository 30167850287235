import { useEffect } from "react";
import types from "../store/Types";
import { useAppDispatch, useAppSelector } from "../hooks/Store.hook";

const ToastView = () => {
    //
    const { toasts } = useAppSelector((state) => state.toasts);

    //
    const dispatch = useAppDispatch();

    //

    useEffect(() => {
        const toastTimeouts: any[] = [];

        toasts.forEach((toast) => {
            const exists = toasts.findIndex(
                (toastItem) => toastItem.createdAt == toast.createdAt
            );
            if (!exists) {
                toastTimeouts.push({
                    toast: toast,
                    timeout: setTimeout(() => {
                        dispatch({
                            type: types.TYPE_REMOVE_TOAST,
                            payload: toast,
                        });
                    }, 3000),
                });
            }
        });
    }, [toasts, dispatch]);

    return (
        <div className="fixed transition-opacity top-3 right-3 z-40">
            {toasts &&
                toasts.map((toast) => (
                    <div
                        key={toast.createdAt}
                        className={
                            "w-full transition-all h-10 animate-fade-in-down flex transition-none pl-4  pr-4 ease-in-out text-sm hover:bg-opacity-90 items-center text-center rounded font-normal z-50 mb-5 " +
                            (toast.type === "Success"
                                ? " bg-custom-successTinted text-custom-success"
                                : "") +
                            " " +
                            (toast.type === "Warning"
                                ? " bg-custom-warningTinted text-custom-warning"
                                : "") +
                            " " +
                            (toast.type === "Danger"
                                ? " bg-custom-dangerTinted text-custom-danger"
                                : "")
                        }
                    >
                        {toast.msg}
                    </div>
                ))}
        </div>
    );
};

export default ToastView;
