import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import { useEventTracking } from "../lib/useEventTracking";

const NoMatch = () => {
    const eventTracking = useEventTracking();
    const router = useHistory();
    const routerP = useParams();

    useEffect(() => {
        console.log("router", router);
        console.log("useParams", routerP);

        eventTracking.onTrackPage("404 page");
    }, []);

    return (
        <div className="w-screen h-screen flex flex-row animate-fade-in">
            <Logo className="fixed left-4 top-4 w-32" />
            <div className="bg-white w-full md:w-4/12 flex min-h-screen justify-center items-center">
                <div className="max-w-sm w-full h-auto flex flex-col text-center pr-3 pl-3">
                    <h1 className="text-xl mb-3 font-semibold">
                        404: Not found
                    </h1>

                    <p className="text-sm mb-10">
                        We couldn't find what you were looking for.
                    </p>
                </div>
            </div>
            <div className="bg-custom-hover w-8/12 bg-signin-bg bg-no-repeat bg-cover hidden md:block"></div>
        </div>
    );
};

export default NoMatch;
