/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, useCallback, useEffect, useState } from "react";

export const INPUT_TYPES = {
    TEXT: "text",
    EMAIL: "email",
    PASSWORD: "password",
};
export const INPUT_CHECKS = {
    MIN_6_LENGTH: "CHECK_PASSWORD_6_LENGTH",
    EMAIL: "CHECK_EMAIL",
    INPUT_REQUIRED: "INPUT_REQUIRED",
};

type InputElement = {
    value: any;
    onErrorCheck: () => string;
    setNewValue: (newValue: string) => void;
    view: ReactElement;
};

export function useInputEle(
    inputValue: string,
    type: string,
    placeholder?: string,
    errorCheck?: string[],
    errorInput?: string
): InputElement {
    const [value, setValue] = useState<string>(inputValue || "");

    const [error, setError] = useState<string>(errorInput || "");
    const [className, setClassName] = useState<string>();
    const [pristine, setPristine] = useState<boolean>(true);

    const onErrorCheck = useCallback(() => {
        let _error: string;
        _error = "";

        if (errorCheck?.includes(INPUT_CHECKS.MIN_6_LENGTH)) {
            if (value.length < 6) {
                _error = "Min. 6 characters";
            }
        }

        if (errorCheck?.includes(INPUT_CHECKS.EMAIL)) {
            if (
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    value
                ) === false
            ) {
                _error = "Please enter valid email";
            }
        }

        if (errorCheck?.includes(INPUT_CHECKS.INPUT_REQUIRED)) {
            if (value.trim() === "") {
                _error = "Need to fill out the field";
            }
        }

        setError(_error);

        return _error;
    }, [value, errorCheck]);

    const onClick = useCallback(() => {
        //setPristine(false);
    }, []);

    const onFocus = useCallback(() => {
        //setPristine(false);
    }, []);

    useEffect(() => {
        const handler = setTimeout(() => {
            if (!pristine) {
                onErrorCheck();
            }
        }, 1000);
        return () => {
            clearTimeout(handler);
        };
    }, [value, pristine, onErrorCheck]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setPristine(false);
            setError(""); //Clear
        } else {
            setPristine(true);
        }
        setValue(e.target.value);
    };

    useEffect(() => {
        if (error !== "") {
            setClassName("border-custom-danger");
        } else {
            setClassName("");
        }
    }, [error]);

    return {
        value: value,
        onErrorCheck: () => {
            return onErrorCheck();
        },
        setNewValue: (newValue: string) => {
            setValue(newValue);
        },
        view: (
            <div className="w-full relative flex items-center mb-2 flex-col">
                <input
                    placeholder={placeholder || ""}
                    type={type || "text"}
                    {...{
                        value: value,
                        className:
                            "input " +
                            className,
                        onChange,
                        onClick,
                        onFocus,
                    }}
                />
                {error && (
                    <span className="text-sm w-full mt-2 text-left text-custom-danger select-none bg-custom-dangerTinted pl-3 pr-3 pt-3 pb-3">
                        {error}
                    </span>
                )}
            </div>
        ),
    };
}
