import { useContext, useEffect, useState } from "react";
import API, { URL } from "../lib/API";
import types from "../store/Types";
import { LoadingClass } from "../lib/LoadingClass";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/Store.hook";
import { AppLifeContext } from "../lib/Context";
import {
    useInputEle,
    INPUT_CHECKS,
    INPUT_TYPES,
} from "../hooks/useInputEle.hook";
import { useLocalStorage } from "../hooks/useLocalStorage.hook";
import Constants from "../lib/Constants";
import { isObjSet } from "../lib/IsObjSet";
import Toast from "../models/Toast.type";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import { ReactComponent as IconSpotify } from "../assets/images/icon_spotify.svg";
import { useEventTracking } from "../lib/useEventTracking";

const Signin = () => {
    //
    const inputEmail = useInputEle(
        "",
        INPUT_TYPES.EMAIL,
        "Enter your email address",
        [INPUT_CHECKS.EMAIL, INPUT_CHECKS.INPUT_REQUIRED]
    );
    const inputPassword = useInputEle(
        "",
        INPUT_TYPES.PASSWORD,
        "Enter your password",
        [INPUT_CHECKS.MIN_6_LENGTH, INPUT_CHECKS.INPUT_REQUIRED]
    );

    //
    const appCtx = useContext(AppLifeContext);
    const eventTracking = useEventTracking();

    //
    const [isLoadingEmailPw, setLoadingEmailPw] = useState(false);
    const [isLoadingSpotify, setLoadingSpotify] = useState(false);

    //
    const { onboarding } = useAppSelector((state) => state.onboarding);
    const [, setUserLSValue] = useLocalStorage<any>(
        Constants.LOCALSTORAGE_USER_KEY
    );
    const [accessTokenLS, setAccessTokenLS] = useLocalStorage<any>(
        Constants.LOCALSTORAGE_ACCESS_TOKEN
    );

    //
    const router = useHistory();
    const dispatch = useAppDispatch();

    //If we have a user in localstorage, then use that to access the Dashboard
    useEffect(() => {
        if (isObjSet(accessTokenLS)) {
            API.setAccessToken(accessTokenLS);
            API.onGetMe()
                .then((res) => {
                    setUserLSValue(res.data.data);
                    dispatch({
                        type: types.TYPE_SET_USER,
                        payload: res.data.data,
                    });
                    router.push("/app");
                })
                .catch((e) => {
                    setAccessTokenLS(undefined);
                    console.log("e", e);
                });
        }
    }, [accessTokenLS]);

    useEffect(() => {
        eventTracking.onTrackPage("Signin");
    }, []);

    useEffect(() => {
        if (isObjSet(onboarding)) {
            router.push("/onboarding/1");
        }
    }, [onboarding, router]);

    const onLogin = (
        event:
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
            | React.FormEvent<HTMLFormElement>
    ) => {
        event.preventDefault();

        if (
            inputPassword.onErrorCheck() !== "" ||
            inputEmail.onErrorCheck() !== ""
        ) {
            return;
        }

        eventTracking.onTrackEvent("Sign in pressed");

        setLoadingEmailPw(true);

        API.onLogin(inputEmail.value, inputPassword.value)
            .then((res) => {
                setLoadingEmailPw(false);
                API.setAccessToken(res.data.data.access_token);

                appCtx.trackingSetUserId("" + res.data.data.user.id);
                setAccessTokenLS(res.data.data.access_token);
                setUserLSValue(res.data.data.user);
            })
            .catch((e) => {
                console.log("e", e);

                dispatch({
                    type: types.TYPE_PUSH_TOAST,
                    payload: {
                        msg:
                            e?.response?.data?.message ||
                            "Something happened. Please try again.",
                        type: "Danger",
                    } as Toast,
                });
                setLoadingEmailPw(false);
            });
    };

    const onSpotify = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault();

        setLoadingSpotify(true);

        window.location.href = URL + "login/spotify";
        //login/spotify
    };

    return (
        <div className="w-screen h-screen flex flex-row">
            <Logo className="fixed left-4 top-4 w-32 z-10" />
            <div className="bg-white w-full md:w-4/12 flex min-h-screen justify-center items-center">
                <div className="max-w-sm w-full h-auto flex flex-col text-center pr-3 pl-3">
                    <h1 className="text-xl mb-3 font-semibold">
                        Welcome back!
                    </h1>

                    <p className="text-sm mb-10">
                        The world is waiting for your music!
                    </p>
                    <form onSubmit={(e) => onLogin(e)}>
                        {inputEmail.view}

                        {inputPassword.view}

                        <button
                            className={
                                "button-primary " +
                                LoadingClass(isLoadingEmailPw)
                            }
                            onClick={(e) => onLogin(e)}
                        >
                            Sign in
                        </button>
                    </form>

                    <p className="text-sm mb-5 mt-5 text-custom-medium">or</p>

                    <button
                        className={
                            "button-spotify flex flex-row justify-center " +
                            LoadingClass(isLoadingSpotify)
                        }
                        onClick={(e) => onSpotify(e)}
                    >
                        <span>Sign in with Spotify</span>

                        <IconSpotify className="ml-2"></IconSpotify>
                    </button>

                    {/* <p className="text-sm	mt-5 text-custom-medium ">
                        Don’t have a Blurry account?
                    </p> */}
                    {/* <p className="text-sm	mt-0 text-custom-medium">
                        What are you waiting for?{" "}
                        <u>
                            <b>
                                <Link to="/signup">Get started.</Link>
                            </b>
                        </u>
                    </p> */}
                    <p className="text-sm mt-5 text-custom-medium">
                        Did you{" "}
                        <u>
                            <b>
                                <Link to="/forgotpassword">
                                    forget your password?
                                </Link>
                            </b>
                        </u>
                    </p>
                </div>
            </div>
            <div className="bg-custom-light w-8/12 bg-signin-bg bg-no-repeat bg-cover hidden md:block"></div>
        </div>
    );
};

export default Signin;
