import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import LoadingLargeView from "../../components/LoadingLargeView.component";
import { useAppDispatch } from "../../hooks/Store.hook";
import { useLocalStorage } from "../../hooks/useLocalStorage.hook";
import API from "../../lib/API";
import Constants from "../../lib/Constants";
import { AppLifeContext } from "../../lib/Context";
import { useEventTracking } from "../../lib/useEventTracking";
import { isObjSet } from "../../lib/IsObjSet";
import Store from "../../models/Store.type";
import types from "../../store/Types";

const OnboardingLoadingFinish = () => {
    const [signupLoading, setSignupLoading] = useState<any>();
    const { onboarding } = useSelector((state: Store) => state.onboarding);
    const { user } = useSelector((state: Store) => state.user);

    const [, setUserLSValue] = useLocalStorage<any>(
        Constants.LOCALSTORAGE_USER_KEY
    );
    //
    const eventTracking = useEventTracking();

    //
    const appCtx = useContext(AppLifeContext);
    const router = useHistory();
    const dispatch = useAppDispatch();
    useEffect(() => {
        //Dont do it double
        if (signupLoading) {
            return;
        }

        setSignupLoading(
            API.onPutPreferences(onboarding)
                .then((res) => {
                    setUserLSValue(res.data.data);
                    dispatch({
                        type: types.TYPE_SET_USER,
                        payload: res.data.data,
                    });

                    //Clean up onboarding stored in Redux
                    dispatch({
                        type: types.TYPE_SET_ONBOARDING,
                        payload: undefined,
                    });

                    API.onGetMe()
                        .then((res) => {
                            setUserLSValue(res.data.data);
                            dispatch({
                                type: types.TYPE_SET_USER,
                                payload: res.data.data,
                            });
                        })
                        .catch((e) => {
                            console.log("e", e);
                        });

                    router.push("/app");
                })
                .catch(() => {
                    router.push("/app");
                })
        );
    }, [onboarding, dispatch, setUserLSValue, appCtx, router, signupLoading]);

    useEffect(() => {
        eventTracking.onTrackPage("Onboarding Loading Finish");
    }, []);

    useEffect(() => {
        if (isObjSet(user)) {
            return router.push("/app");
        }
        if (isObjSet(onboarding)) {
            if (!onboarding.artist_types) {
                router.push("/onboarding/1");
            } else if (onboarding.genres?.length === 0) {
                router.push("/onboarding/2");
            } else if (onboarding.artists?.length === 0) {
                router.push("/onboarding/3");
            }
        } else {
            router.push("/signup");
        }
    }, [user, onboarding, router]);

    return (
        <div className="w-screen h-screen flex flex-col bg-white justify-center items-center animate-fade-in">
            <LoadingLargeView message="Hang on while we set up your account..."></LoadingLargeView>
        </div>
    );
};

export default OnboardingLoadingFinish;
