import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.scss";

import Store from "./store/Store";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { AppLifeContextProvider } from "./lib/Context";

//Pages
import Signin from "./pages/Signin";
import NoMatch from "./pages/NoMatch";
import OnboardingLoadingFinish from "./pages/Onboarding/OnboardingLoadingFinish";
import ResetPw from "./pages/ResetPw";
import ForgotPw from "./pages/ForgotPw";
import Signup from "./pages/Signup";

//Components
import ToastView from "./components/ToastView.component";
import Layout from "./components/Layout.component";
import LoginSpotify from "./pages/LoginSpotify";
const Onboarding = React.lazy(() => import("./pages/Onboarding/Onboarding"));
const App = React.lazy(() => import("./pages/App/App"));

//Sentry
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
Sentry.init({
    dsn: "https://32a2d3efd4914624b72003370d133583@o967304.ingest.sentry.io/5918370",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0,
});


ReactDOM.render(
    <React.StrictMode>
        <AppLifeContextProvider
            value={{ trackingToken: "5723d18e0b7da097c6d97ce5c45debdd" }}
        >
            <Provider store={Store}>
                <Layout>
                    <ToastView></ToastView>
                    <BrowserRouter>
                        <Suspense fallback={<div></div>}>
                            <Switch>
                                <Route exact path={["/", "/signin"]}>
                                    <Signin />
                                </Route>
                                <Route exact path="/signup">
                                    <Signup />
                                </Route>
                                <Route exact path="/register/:uuid">
                                    <Signup />
                                </Route>
                                <Route exact path="/forgotpassword">
                                    <ForgotPw />
                                </Route>
                                <Route exact path="/resetpassword">
                                    <ResetPw />
                                </Route>
                                <Route exact path="/login/spotify">
                                    <LoginSpotify />
                                </Route>
                                <Route
                                    exact
                                    path={[
                                        "/onboarding",
                                        "/onboarding/1",
                                        "/onboarding/2",
                                        "/onboarding/3",
                                        "/onboarding/4",
                                        "/onboarding/5",
                                    ]}
                                >
                                    <Onboarding />
                                </Route>

                                <Route exact path={["/onboarding/finish"]}>
                                    <OnboardingLoadingFinish />
                                </Route>
                                <Route
                                    exact
                                    path={[
                                        "/app",
                                        "/app/discover",
                                        "/app/assist",
                                        "/app/settings",
                                        "/app/profile/:uuid/favourites",
                                        "/app/profile/:uuid/tracks",
                                        "/app/profile/:uuid/about",
                                        "/app/requests",
                                        "/app/messages",
                                        "/app/messages/:id",
                                        "/app/messageaccept/:id/:firstTimeOpening",
                                        "/app/requests/received",
                                        "/app/requests/sent",
                                        "/app/search",
                                        "/app/search/player",
                                        "/app/search/list",
                                        "/app/dm/:uuid",
                                    ]}
                                >
                                    <App />
                                </Route>
                                <Route path="*">
                                    <NoMatch />
                                </Route>
                            </Switch>
                        </Suspense>
                    </BrowserRouter>
                </Layout>
            </Provider>
        </AppLifeContextProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
