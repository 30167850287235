import types from "../Types";
import User from "../../models/User.type";
import Track from "../../models/Track.type";
import Blueprint from "../../models/Blueprint.type";

type UserReducerAction = {
    type: string;
    payload: User | undefined | Track[] | number | Blueprint;
};
type UserReducerState = {
    user: User | undefined;
    userTracks: Track[] | undefined;
    userOnboardingDataUpdate: number;
    blueprint: Blueprint;
    updateUserContent: number;
};
const initialState: UserReducerState = {
    user: {} as User,
    userTracks: [] as Track[],
    userOnboardingDataUpdate: 0,
    blueprint: {} as Blueprint,
    updateUserContent: 0,
};

export const userReducers = (
    state: UserReducerState = initialState,
    action: UserReducerAction
) => {
    switch (action.type) {
        case types.TYPE_SET_USER:
            return {
                ...state,
                user: action.payload as User,
            };
        case types.TYPE_SET_USER_TRACKS:
            return {
                ...state,
                userTracks: action.payload as Track[],
            };
        case types.TYPE_SET_CONCAT_USER_TRACKS:
            return {
                ...state,
                userTracks:
                    state.userTracks != undefined
                        ? [
                              ...state.userTracks.concat(
                                  action.payload as Track[]
                              ),
                          ]
                        : [],
            };
        case types.TYPE_SET_USER_ONBOARDING_DATA_UPDATE:
            return {
                ...state,
                userOnboardingDataUpdate: Math.random(),
            };
        case types.TYPE_SET_USER_BLUEPRINT:
            return {
                ...state,
                blueprint: action.payload as Blueprint,
            };
        case types.TYPE_UPDATE_USER_CONTENT:
            return {
                ...state,
                userTracks: [],
                updateUserContent: Math.random(),
            };
        default:
            return state;
    }
};
