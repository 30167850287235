const types = {
    //User
    TYPE_SET_USER: "TYPE_SET_USER",
    TYPE_SET_USER_TRACKS: "TYPE_SET_USER_TRACKS",
    TYPE_SET_CONCAT_USER_TRACKS: "TYPE_SET_CONCAT_USER_TRACKS",
    TYPE_SET_USER_ONBOARDING_DATA_UPDATE:
        "TYPE_SET_USER_ONBOARDING_DATA_UPDATE",
    TYPE_SET_USER_BLUEPRINT: "TYPE_SET_USER_BLUEPRINT",
    TYPE_UPDATE_USER_CONTENT: "TYPE_UPDATE_USER_CONTENT",

    //Onboarding
    TYPE_SET_ONBOARDING: "TYPE_SET_ONBOARDING",
    TYPE_SET_ONBOARDING_TYPES_GENRES: "TYPE_SET_ONBOARDING_TYPES",
    TYPE_SET_ONBOARDING_TYPES_ARTISTS: "TYPE_SET_ONBOARDING_TYPES_ARTISTS",
    TYPE_SET_ONBOARDING_TYPES_ARTIST_TYPES:
        "TYPE_SET_ONBOARDING_TYPES_ARTIST_TYPES",

    //Search
    TYPE_SEARCH_PARAM: "TYPE_SEARCH_PARAM",
    TYPE_SET_SEARCH_CURRENT_TRACK: "TYPE_SET_SEARCH_CURRENT_TRACK",
    TYPE_SEARCH_PLAYLIST: "TYPE_SEARCH_PLAYLIST",
    TYPE_SEARCH_PLAYLIST_CONCAT: "TYPE_SEARCH_PLAYLIST_CONCAT",
    TYPE_SEARCH_SET_ROUTE: "TYPE_SEARCH_SET_ROUTE",
    TYPE_SEARCH_NEXT: "TYPE_SEARCH_NEXT",
    TYPE_SEARCH_PREV: "TYPE_SEARCH_PREV",

    //Push toast
    TYPE_PUSH_TOAST: "TYPE_PUSH_TOAST",
    TYPE_REMOVE_TOAST: "TYPE_REMOVE_TOAST",

    //Playlist
    TYPE_SET_PLAYLIST: "TYPE_SET_PLAYLIST",
    TYPE_CONCAT_PLAYLIST: "TYPE_CONCAT_PLAYLIST",

	//Collaboration
	TYPE_SET_REQUEST_COUNT: "TYPE_SET_REQUEST_COUNT",
	TYPE_SET_REQUEST_COUNT_PAGE: "TYPE_SET_REQUEST_COUNT_PAGE",
	TYPE_SET_DM_REQUESTS: "TYPE_SET_DM_REQUESTS",
	TYPE_CONCAT_DM_REQUESTS: "TYPE_CONCAT_DM_REQUESTS",

	TYPE_SET_COLLAB_RECEIVED_LIST: "TYPE_SET_COLLAB_RECEIVED_LIST",
	TYPE_CONCAT_COLLAB_RECEIVED_LIST: "TYPE_CONCAT_COLLAB_RECEIVED_LIST",
	TYPE_SET_COLLAB_RECEIVED_LIST_PAGE: "TYPE_SET_COLLAB_RECEIVED_LIST_PAGE",

	TYPE_SET_COLLAB_SENT_LIST: "TYPE_SET_COLLAB_SENT_LIST",
	TYPE_CONCAT_COLLAB_SENT_LIST: "TYPE_CONCAT_COLLAB_SENT_LIST",
	TYPE_SET_COLLAB_SENT_LIST_PAGE: "TYPE_SET_COLLAB_SENT_LIST_PAGE",

	TYPE_SET_UPDATE_COLLAB_DATA: "TYPE_SET_UPDATE_COLLAB_DATA",
	
	TYPE_SET_REQUEST_COUNT_INCREMENT: "TYPE_SET_REQUEST_COUNT_INCREMENT",
	TYPE_SET_REQUEST_COUNT_REDUCE: "TYPE_SET_REQUEST_COUNT_REDUCE",

    //Audio player
    TYPE_SET_AUDIOPLAYER: "TYPE_SET_AUDIOPLAYER",
    TYPE_SET_PLAYLIST_SOURCE: "TYPE_SET_PLAYLIST_SOURCE",
    TYPE_SET_CURRENT_TRACK: "TYPE_SET_CURRENT_TRACK",
    TYPE_SET_LOOPING_TRACK: "TYPE_SET_LOOPING_TRACK",
    TYPE_SET_FULL_TRACK_AVAILABLE: "TYPE_SET_FULL_TRACK_AVAILABLE",
    TYPE_FIRE_ACTION: "TYPE_FIRE_ACTION",
    TYPE_NEXT_TRACK_IN_PLAYLIST: "TYPE_NEXT_TRACK_IN_PLAYLIST",
    TYPE_PREV_TRACK_IN_PLAYLIST: "TYPE_PREV_TRACK_IN_PLAYLIST",
    TYPE_SET_AUDIOPLAYING: "TYPE_SET_AUDIOPLAYING",
    TYPE_SET_LYRICS_POPUP: "TYPE_SET_LYRICS_POPUP",
    TYPE_SET_STORY_POPUP: "TYPE_SET_STORY_POPUP",
    TYPE_SET_AUDIOVOLUME: "TYPE_SET_AUDIOVOLUME",
    TYPE_FILTER_ON_PLAYLIST: "TYPE_FILTER_ON_PLAYLIST",

    //Collab
    TYPE_SET_COLLAB_SHOW_POPUP: "TYPE_SET_COLLAB_SHOW_POPUP",
    TYPE_SET_COLLAB_TRACK: "TYPE_SET_COLLAB_TRACK",

    //Upload track
    TYPE_SET_UPLOAD_TRACK_SHOW_POPUP: "TYPE_SET_UPLOAD_TRACK_SHOW_POPUP",
    TYPE_SET_UPLOAD_TRACK_STEP: "TYPE_SET_UPLOAD_TRACK_STEP",
    TYPE_EDIT_TRACK_SHOW_POPUP: "TYPE_EDIT_TRACK_SHOW_POPUP",
    TYPE_EDIT_TRACK_TRACK: "TYPE_EDIT_TRACK_TRACK",

    // DirectMessages
    TYPE_ADD_COLLABORATION_REQUEST: "TYPE_ADD_COLLABORATION_REQUEST",
    TYPE_REMOVE_COLLABORATON_REQUEST: "TYPE_REMOVE_COLLABORATION_REQUEST",

    //Invites
    TYPE_SHOW_INVITES_POPUP: "TYPE_SHOW_INVITES_POPUP",
    TYPE_INVITES_SET_STEP: "TYPE_INVITES_SET_STEP",
};

export default types;
