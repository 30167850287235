import { useEffect, useState } from "react";
import API from "../lib/API";
import { LoadingClass } from "../lib/LoadingClass";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/Store.hook";
import {
    useInputEle,
    INPUT_CHECKS,
    INPUT_TYPES,
} from "../hooks/useInputEle.hook";
import types from "../store/Types";
import Toast from "../models/Toast.type";
import { isObjSet } from "../lib/IsObjSet";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import { ReactComponent as IconCheck } from "../assets/images/icon_check.svg";
import { useEventTracking } from "../lib/useEventTracking";

const ResetPw = () => {
    //
    const inputPassword = useInputEle(
        "",
        INPUT_TYPES.PASSWORD,
        "Enter new password",
        [INPUT_CHECKS.MIN_6_LENGTH, INPUT_CHECKS.INPUT_REQUIRED]
    );
    const inputPasswordRepeat = useInputEle(
        "",
        INPUT_TYPES.PASSWORD,
        "Please repeat your password",
        [INPUT_CHECKS.MIN_6_LENGTH, INPUT_CHECKS.INPUT_REQUIRED]
    );

    //
    const eventTracking = useEventTracking();
    //
    const [isLoading, setLoading] = useState(false);
    const [isForgotSuccess, setForgotSuccess] = useState(false);

    //
    const [token, setToken] = useState("");
    const [email, setEmail] = useState("");

    //
    const { user } = useAppSelector((state) => state.user);

    //
    const router = useHistory();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        setToken(urlParams.get("token") || "");
        setEmail(urlParams.get("email") || "");
    }, []);

    useEffect(() => {
        eventTracking.onTrackPage("Reset password");
    }, []);

    useEffect(() => {
        if (isObjSet(user)) {
            dispatch({
                type: types.TYPE_PUSH_TOAST,
                payload: {
                    msg: "You are already logged in",
                    type: "Danger",
                } as Toast,
            });
            router.push("/app");
        }
    }, [user, router, dispatch]);

    const onResetPw = (
        event:
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
            | React.FormEvent<HTMLFormElement>
    ) => {
        event.preventDefault();

        if (inputPassword.onErrorCheck() != "") {
            return;
        }

        if (inputPassword.value !== inputPasswordRepeat.value) {
            return dispatch({
                type: types.TYPE_PUSH_TOAST,
                payload: {
                    msg: "The two passwords are not the same",
                    type: "Danger",
                } as Toast,
            });
        }

        eventTracking.onTrackEvent("Reset password button");

        setLoading(true);

        API.onResetPassword(token, email, inputPassword.value)
            .then((_response) => {
                setLoading(false);
                setForgotSuccess(true);
            })
            .catch((_e) => {
                setLoading(false);
            });
    };

    const onGoToSignin = () => {
        router.push("/signin");
    };

    return (
        <div className="w-screen h-screen flex flex-row animate-fade-in">
            <Logo className="fixed left-4 top-4 w-32" />
            <div className="bg-white w-full md:w-4/12 flex min-h-screen justify-center items-center">
                <IconCheck className="w-0 h-0" />
                <div className="max-w-sm w-full h-auto flex flex-col text-center pr-3 pl-3">
                    {!isForgotSuccess && (
                        <>
                            <h1 className="text-xl mb-3 font-semibold">
                                Reset your password
                            </h1>

                            <p className="text-sm mb-10">
                                Enter your new passwords below
                            </p>

                            <form onSubmit={(e) => onResetPw(e)}>
                                {inputPassword.view}
                                {inputPasswordRepeat.view}

                                <button
                                    className={
                                        "button-primary " +
                                        LoadingClass(isLoading)
                                    }
                                    onClick={(e) => onResetPw(e)}
                                >
                                    Reset password
                                </button>
                            </form>
                        </>
                    )}
                    {isForgotSuccess && (
                        <div className="animate-fade-in-down flex w-full flex-col items-center">
                            <IconCheck />
                            <h1 className="text-xl mb-3 font-semibold">
                                Success!
                            </h1>

                            <p className="text-sm mb-5">
                                Your password is now changed.
                            </p>

                            <button
                                className={"button-primary "}
                                onClick={() => onGoToSignin()}
                            >
                                Sign in
                            </button>
                        </div>
                    )}

                    <p className="text-sm mt-5 text-custom-medium ">
                        Got your memory back?
                    </p>
                    <p className="text-sm mt-0 text-custom-medium">
                        <u>
                            <b>
                                <Link to="/signin">Sign in</Link>
                            </b>
                        </u>
                    </p>
                </div>
            </div>
            <div className="bg-custom-hover w-8/12 bg-forgotpw-bg bg-no-repeat bg-cover hidden md:block"></div>
        </div>
    );
};

export default ResetPw;
