const Constants = {
    DEBUG: false,
    VERSION: "0.1.4",

    //
    LOCALSTORAGE_ACCESS_TOKEN: "LOCALSTORAGE_ACCESS_TOKEN",
    LOCALSTORAGE_USER_KEY: "LOCALSTORAGE_USER_KEY",
    LOCALSTORAGE_DARKMODE_KEY: "LOCALSTORAGE_DARKMODE_KEY",

	//
	PAGINATION_CLIENT_HEIGHT_LOAD_CONTENT_PAGE_2: 900,
	PAGINATION_CLIENT_HEIGHT_LOAD_CONTENT_PAGE_3: 1800,

    //
    LS_TYPE: {
        SKIP: "skip",
        SESSION_INTERRUPTED: "session_interrupted",
        FAVOURITE_ADD: "favourite_add",
        FAVOURITE_REMOVE: "favourite_remove",
        COLLABORATION_REQUESTED_ADD: "collaboration_requested_add",
        COLLABORATION_REQUESTED_REMOVE: "collaboration_requested_remove",
    },
};

export default Constants;
