import { useContext, useEffect } from "react";
import { useAppSelector } from "../hooks/Store.hook";
import { AppLifeContext } from "./Context";
import Constants from "./Constants";
import mixpanel from "mixpanel-browser";

export function useEventTracking() {
    const { user } = useAppSelector((state) => state.user);
    const appCtx = useContext(AppLifeContext);

    useEffect(() => {
        if (!Constants.DEBUG) {
            mixpanel.init(appCtx.trackingToken);
        }
    }, []);

    useEffect(() => {
        if (!Constants.DEBUG) {
            if (appCtx.trackingUserId && appCtx.trackingUserId !== "") {
                mixpanel.people.set({
                    id: user?.id || "",
                    email: user?.email || "",
                });
                mixpanel.identify(appCtx.trackingUserId);
            }
        }
    }, [user, appCtx.trackingUserId]);

    const onTrackPage = (page: string) => {
        if (Constants.DEBUG) {
            console.log(`Tracked page '${page}' `);
        } else {
            mixpanel.track(page, { type: "page" });
        }
    };
    const onTrackEvent = (event: string) => {
        if (Constants.DEBUG) {
            console.log(`Tracked event '${event}' `);
        } else {
            mixpanel.track(event, { type: "event" });
        }
    };

    return { onTrackPage, onTrackEvent };
}
