import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hooks/Store.hook";
import API from "../../lib/API";
import Collaboration from "../../models/Collaboration.type";
import Message from "../../models/Message.type";
import { RootState } from "../Store";

interface CollaborationsState {
    collaborations: Record<string, Collaboration>;
    isLoadingCollaborations: boolean;
    hasMoreCollaborations: boolean;
    unreadRequests: number;
    reloadContent: number;
    currentPage: number;
    type?: string[];
    status?: string[];
    trackId?: string;
}

const initialState: CollaborationsState = {
    collaborations: {},
    unreadRequests: 0,
    reloadContent: 0,
    isLoadingCollaborations: false,
    hasMoreCollaborations: true,
    currentPage: 1,
    type: [""],
    status: [""],
    trackId: "",
};

export const fetchMoreCollaborations = createAsyncThunk(
    "collaborations/fetchMoreCollaborations",
    (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        if (!state.collaborations.isLoadingCollaborations)
            return Promise.reject();
        if (!state.collaborations.hasMoreCollaborations)
            return Promise.reject();
        return API.onGetCollaborations(
            state.collaborations.currentPage,
            state.collaborations.type,
            state.collaborations.status,
            state.collaborations.trackId
        ).then((response) => response.data);
    }
);

export const collaborationsSlice = createSlice({
    name: "collaborations",
    initialState,
    reducers: {
        incrementUnreadRequests(state) {
            state.unreadRequests++;
        },
        resetUnreadRequests(state) {
            state.unreadRequests = 0;
        },
        reloadContent(state) {
            state.reloadContent++;
        },
        resetCollaborations(state) {
            state.currentPage = 1;
            state.hasMoreCollaborations = true;
            state.isLoadingCollaborations = false;
            const collaborations: Record<string, Collaboration> = {};
            state.collaborations = collaborations;
        },
        setCollaborations(state, action: PayloadAction<Collaboration[]>) {
            const collaborations: Record<string, Collaboration> = {};
            for (const collaboration of action.payload) {
                collaborations[collaboration.id] = collaboration;
            }
            state.collaborations = collaborations;
        },
        pushCollaboration(state, action: PayloadAction<Collaboration>) {
            action.payload.messages = [];
            state.collaborations[action.payload.id] = action.payload;
        },
        pushMessages(
            state,
            action: PayloadAction<{
                collaborationId: string;
                messages: Array<Message>;
            }>
        ) {
            const collaboration =
                state.collaborations[action.payload.collaborationId];
            if (!collaboration) return;
            if (!collaboration.messages) {
                collaboration.messages = [];
            }
            state.collaborations[action.payload.collaborationId].messages.push(
                ...action.payload.messages
            );
        },
        unshiftMessages(
            state,
            action: PayloadAction<{
                collaborationId: string;
                messages: Array<Message>;
            }>
        ) {
            const collaboration =
                state.collaborations[action.payload.collaborationId];
            if (!collaboration) return;
            if (!collaboration.messages) {
                collaboration.messages = [];
            }
            collaboration.messages.unshift(...action.payload.messages);
            collaboration.unseen_count = action.payload.messages.length;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchMoreCollaborations.pending, (state) => {
                state.isLoadingCollaborations = true;
            })
            .addCase(fetchMoreCollaborations.fulfilled, (state, action) => {
                const meta = (action.payload as any).meta;
                state.isLoadingCollaborations = false;
                if (meta.last_page === state.currentPage) {
                    state.hasMoreCollaborations = false;
                }
                state.currentPage++;
				//This returns an error in ESLint
                // for (const collaboration of action.payload.data) {
                //     state.collaborations[collaboration.id] = collaboration;
                // }
            })
            .addCase(fetchMoreCollaborations.rejected, (state) => {
                state.isLoadingCollaborations = false;
            });
    },
});

export const {
    setCollaborations,
    pushCollaboration,
    pushMessages,
    resetCollaborations,
    unshiftMessages,
    resetUnreadRequests,
	reloadContent,
    incrementUnreadRequests,
} = collaborationsSlice.actions;

export const selectCollaborations = (state: RootState) =>
    state.collaborations.collaborations;

export function useCollaborations() {
    const dispatch = useDispatch();
    const collaborations = useAppSelector((state) =>
        Object.values(state.collaborations.collaborations)
    );

    const loadMore = () => {
        dispatch(fetchMoreCollaborations());
    };

    return {
        collaborations,
        loadMore,
    };
}
export const selectUnreadRequests = (state: RootState) =>
    state.collaborations.unreadRequests;
	
export default collaborationsSlice.reducer;