import { useContext, useEffect } from "react";
import API from "../lib/API";
import types from "../store/Types";
import { useHistory } from "react-router";
import { useAppDispatch } from "../hooks/Store.hook";
import { AppLifeContext } from "../lib/Context";
import { useLocalStorage } from "../hooks/useLocalStorage.hook";
import Constants from "../lib/Constants";
import Toast from "../models/Toast.type";
import LoadingLargeView from "../components/LoadingLargeView.component";
import { useEventTracking } from "../lib/useEventTracking";
import Onboarding from "../models/Onboarding.type";

const LoginSpotify = () => {
    //
    const appCtx = useContext(AppLifeContext);
    const eventTracking = useEventTracking();

    //
    const [, setUserLSValue] = useLocalStorage<any>(
        Constants.LOCALSTORAGE_USER_KEY
    );
    const [, setAccessTokenLS] = useLocalStorage<any>(
        Constants.LOCALSTORAGE_ACCESS_TOKEN
    );

    //
    const router = useHistory();
    const dispatch = useAppDispatch();

    const findGetParameter = (parameterName: string) => {
        let result = null,
            tmp = [];
        const items = location.search.substr(1).split("&");
        for (let index = 0; index < items.length; index++) {
            tmp = items[index].split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        }
        return result;
    };

    useEffect(() => {
        eventTracking.onTrackPage("Spotify Login");
    }, []);

    useEffect(() => {
        if (
            findGetParameter("token") != "" &&
            findGetParameter("token") != null
        ) {
            setAccessTokenLS(findGetParameter("token"));
            API.setAccessToken(findGetParameter("token") || "");

            API.onGetMe()
                .then((res) => {
                    appCtx.trackingSetUserId("" + res.data.data.id);

                    setUserLSValue(res.data.data);

                    dispatch({
                        type: types.TYPE_SET_USER,
                        payload: res.data.data,
                    });

                    if (res.data.data?.genres?.length === 0) {
                        const onboarding: Onboarding = {
                            name: res.data.data.name || "",
                        };
                        dispatch({
                            type: types.TYPE_SET_ONBOARDING,
                            payload: onboarding,
                        });
                        router.push("/onboarding/1");
                    } else {
                        router.push("/app");
                    }
                })
                .catch((e) => {
                    setAccessTokenLS(undefined);
                    dispatch({
                        type: types.TYPE_PUSH_TOAST,
                        payload: {
                            msg:
                                e?.response?.data?.message ||
                                "Something happened. Please try again.",
                            type: "Danger",
                        } as Toast,
                    });
                    router.push("/signup");
                });
        } else {
            router.push("/signup");
        }
    }, []);

    return (
        <div className="w-screen h-screen flex flex-row justify-center items-center">
            <LoadingLargeView message="Signing you in through Spotify..."></LoadingLargeView>
        </div>
    );
};

export default LoginSpotify;
