import { useContext, useEffect, useState } from "react";
import API, { URL } from "../lib/API";
import types from "../store/Types";
import { LoadingClass } from "../lib/LoadingClass";
import { useHistory } from "react-router";
import { Link, useParams } from "react-router-dom";
import { useAppDispatch } from "../hooks/Store.hook";
import { AppLifeContext } from "../lib/Context";
import {
    useInputEle,
    INPUT_CHECKS,
    INPUT_TYPES,
} from "../hooks/useInputEle.hook";
import { useLocalStorage } from "../hooks/useLocalStorage.hook";
import Constants from "../lib/Constants";
import Onboarding from "../models/Onboarding.type";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import { ReactComponent as IconSpotify } from "../assets/images/icon_spotify.svg";
import { useEventTracking } from "../lib/useEventTracking";
import Toast from "../models/Toast.type";

const Signup = () => {
    const { uuid } = useParams<{ uuid: string }>();

    //
    const inputName = useInputEle(
        Constants.DEBUG
            ? "debug" + Math.random().toString(36).substring(7)
            : "",
        INPUT_TYPES.TEXT,
        "Enter your name",
        [INPUT_CHECKS.INPUT_REQUIRED]
    );
    const inputEmail = useInputEle(
        Constants.DEBUG
            ? Math.random().toString(36).substring(7) +
                  "@" +
                  Math.random().toString(36).substring(7) +
                  ".dk"
            : "",
        INPUT_TYPES.EMAIL,
        "Enter your email address",
        [INPUT_CHECKS.EMAIL, INPUT_CHECKS.INPUT_REQUIRED]
    );
    const inputPassword = useInputEle(
        Constants.DEBUG ? "secret123" : "",
        INPUT_TYPES.PASSWORD,
        "Enter your password",
        [INPUT_CHECKS.MIN_6_LENGTH, INPUT_CHECKS.INPUT_REQUIRED]
    );
    const inputPasswordRepeat = useInputEle(
        Constants.DEBUG ? "secret123" : "",
        INPUT_TYPES.PASSWORD,
        "Repeat your password",
        [INPUT_CHECKS.MIN_6_LENGTH, INPUT_CHECKS.INPUT_REQUIRED]
    );

    //
    const appCtx = useContext(AppLifeContext);
    const eventTracking = useEventTracking();

    //
    const [isLoadingEmailPw, setLoadingEmailPw] = useState(false);
    const [isLoadingSpotify, setLoadingSpotify] = useState(false);
    const [isCorrectUuid, setCorrectUuid] = useState(false);
    const [isLoadingUuid, setLoadingUuid] = useState(true);

    //
    const [, setUserLSValue] = useLocalStorage<any>(
        Constants.LOCALSTORAGE_USER_KEY
    );
    const [, setAccessTokenLS] = useLocalStorage<any>(
        Constants.LOCALSTORAGE_ACCESS_TOKEN
    );

    useEffect(() => {
        API.onSignUpCheckUuid(uuid)
            .then((res) => {
                console.log("res", res);
                setCorrectUuid(true);
                setLoadingUuid(false);
            })
            .catch((e) => {
                setCorrectUuid(false);
                console.log("e", e);
                setLoadingUuid(false);
                dispatch({
                    type: types.TYPE_PUSH_TOAST,
                    payload: {
                        msg: "Seems like a wrong token you have provided.",
                        type: "Danger",
                    } as Toast,
                });
            });
    }, [uuid]);

    //
    const router = useHistory();
    const dispatch = useAppDispatch();

    useEffect(() => {
        eventTracking.onTrackPage("Signup");
    }, []);

    const onSignup = (
        event:
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
            | React.FormEvent<HTMLFormElement>
    ) => {
        event.preventDefault();

        if (
            inputEmail.onErrorCheck() !== "" ||
            inputPassword.onErrorCheck() !== "" ||
            inputPasswordRepeat.onErrorCheck() !== "" ||
            inputName.onErrorCheck()
        ) {
            return;
        }

        eventTracking.onTrackEvent("Sign up pressed");

        setLoadingEmailPw(true);

        API.onSignUp(
            uuid,
            inputName.value,
            inputEmail.value,
            inputPassword.value
        )
            .then((res) => {
                setLoadingEmailPw(false);
                API.setAccessToken(res.data.data.access_token);

                appCtx.trackingSetUserId("" + res.data.data.user.id);

                setAccessTokenLS(res.data.data.access_token);
                setUserLSValue(res.data.data.user);

                const onboarding: Onboarding = {
                    name: inputName.value,
                };

                dispatch({
                    type: types.TYPE_SET_ONBOARDING,
                    payload: onboarding,
                });

                dispatch({
                    type: types.TYPE_SET_USER,
                    payload: res.data.data.user,
                });

                router.push("/onboarding/1");
            })
            .catch((_e) => {
                setLoadingEmailPw(false);
            });
    };

    const onSpotify = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault();

        setLoadingSpotify(true);

        window.location.href = URL + "login/spotify?invite_token=" + uuid;
    };

    return (
        <div className="w-screen h-screen flex flex-row">
            <Logo className="fixed left-4 top-4 w-32 z-10" />
            <div className="bg-white w-full md:w-4/12 flex min-h-screen justify-center items-center animate-fade-in">
                <div className="max-w-sm w-full h-auto flex flex-col text-center pr-3 pl-3 ">
                    <h1 className="text-xl mb-3 font-semibold">
                        Welcome to Blurry!
                    </h1>

                    {!isLoadingUuid && !isCorrectUuid && (
                        <>
                            <p className="text-sm mb-10 mt-10 text-custom-danger">
                                Seems like you provided a wrong token, or it has already been used.
                            </p>
                        </>
                    )}
                    {!isLoadingUuid && isCorrectUuid && (
                        <>
                            <p className="text-sm mb-10">
                                The world is waiting for your music!
                            </p>
                            <form onSubmit={(e) => onSignup(e)}>
                                {inputName.view}

                                {inputEmail.view}

                                {inputPassword.view}

                                {inputPasswordRepeat.view}

                                <button
                                    className={
                                        "button-primary " +
                                        LoadingClass(isLoadingEmailPw)
                                    }
                                    onClick={(e) => onSignup(e)}
                                >
                                    Get started
                                </button>
                            </form>

                            <p className="text-sm mb-5 mt-5 text-custom-medium">
                                or
                            </p>

                            <button
                                className={
                                    "button-spotify flex flex-row justify-center " +
                                    LoadingClass(isLoadingSpotify)
                                }
                                onClick={(e) => onSpotify(e)}
                            >
                                <span>Sign up with Spotify</span>
                                <IconSpotify className="ml-2"></IconSpotify>
                            </button>
                        </>
                    )}

                    <p className="text-sm mt-5 text-custom-medium ">
                        Already got a Blurry account?
                    </p>
                    <p className="text-sm mt-0 text-custom-medium">
                        <u>
                            <b>
                                <Link to="/signin">Sign in</Link>
                            </b>
                        </u>
                        {"  "}and start jamming.
                    </p>
                </div>
                <div className="absolute bottom-4 max-w-sm w-full pr-3 pl-3 text-center">
                    <p className="text-sm text-custom-medium">
                        By signing up to Blurry, you acknowledge that you’ve
                        read and agreed with our{" "}
                        <u>
                            <b>
                                <a
                                    href="assets/blurry_terms_and_conditions.pdf"
                                    target="_blank"
                                >
                                    Terms of Services
                                </a>
                            </b>
                        </u>
                        .
                    </p>
                </div>
            </div>
            <div className="bg-custom-hover w-8/12 bg-signup-bg bg-no-repeat bg-cover hidden md:block"></div>
        </div>
    );
};

export default Signup;
