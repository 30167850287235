export const LoadingClass = (
    isLoading: boolean,
    darkmode?: boolean
): string => {
    if (isLoading && !darkmode) {
        return "is-loading";
    }
    if (isLoading && !darkmode) {
        return "is-loading is-loading-black";
    }
    return "";
};
