import Track from "../../models/Track.type";
import types from "../Types";

type CollaborationReducerAction = {
    type: string;
    payload: Track | null;
};

type CollaborationReducerState = {
    collabTrack: Track | null;
    isCollabOpen: boolean;
};

const initialState = {
    collabTrack: null,
    isCollabOpen: false,
};

export const collaborationPopupReducers = (
    state: CollaborationReducerState = initialState,
    action: CollaborationReducerAction
) => {
    switch (action.type) {
        case types.TYPE_SET_COLLAB_SHOW_POPUP:
            return {
                ...state,
                isCollabOpen: action.payload as boolean,
            };
        case types.TYPE_SET_COLLAB_TRACK:
            return {
                ...state,
                collabTrack: action.payload ? (action.payload as Track) : null,
            };
        default:
            return state;
    }
};
