import axios from "axios";
import Blueprint from "../models/Blueprint.type";
import Onboarding from "../models/Onboarding.type";
import { PresignedUrl } from "../models/PresignedUrl.type";
import Track from "../models/Track.type";
import User from "../models/User.type";
import Collaboration from "../models/Collaboration.type";
import Message from "../models/Message.type";
import ListeningSession from "../models/ListeningSession.type";
import OnboardingData from "../models/OnboardingData.type";

type APIData<T> = {
    data: T;
};

type APITableData<T> = {
    data: T[];
    links?: {
        first: string;
        last: string;
        prev: string;
        next: string;
    };
    meta?: {
        current_page: number;
        from: number;
        last_page: number;
        links: [
            {
                url: string;
                label: string;
                active: boolean;
            },
            {
                url: string;
                label: string;
                active: boolean;
            },
            {
                url: string;
                label: string;
                active: boolean;
            }
        ];
        path: string;
        per_page: number;
        to: number;
        total: number;
    };
};
type APIMsg = {
    message: string;
    status?: boolean;
};

export const URL =
    process.env.REACT_APP_API_URL || "https://blurry-api.signifly.io/";

const UNSPLASH_KEY =
    process.env.REACT_APP_UNSPLASH_KEY ||
    "Gk6AkBhsnPYFDHpFjXudRBaK0cWsql5Zz46CHcsQ48Q";

export const URL_API = URL + "api/";

export class API {
    //
    static instance: API;
    protected accessToken: any;

    //
    static getInstance() {
        if (!API.instance) {
            API.instance = new API();
        }
        return API.instance;
    }

    getHeaders = () => {
        const headers = {} as Record<string, string>;

        if (API.getInstance().accessToken) {
            headers.Authorization = "Bearer " + API.instance.accessToken;
            headers["Content-Type"] = "application/json";
        }

        if ((window as any).__echoInstance) {
            const socketId = (
                window as any
            ).__echoInstance.socketId() as string;
            if (socketId) {
                headers["X-Socket-Id"] = socketId;
            }
        }

        return headers;
    };

    static setAccessToken = (_accessToken: string) => {
        API.getInstance().accessToken = _accessToken;
    };

    /**
     * API CALLS BELOW
     */
    static onLogin = async (email: string, password: string) => {
        return axios.request<APIData<{ access_token: string; user: User }>>({
            method: "POST",
            url: URL_API + "login",
            data: { email, password },
        });
    };

    static onSignUp = (
        uuid: string,
        name: string,
        email: string,
        password: string
    ) => {
        return axios.request<APIData<{ access_token: string; user: User }>>({
            method: "POST",
            url: URL_API + "register",
            data: {
                token: uuid,
                name: name,
                email: email,
                password: password,
                password_confirmation: password,
            },
        });
    };

    static onSignUpCheckUuid = (uuid: string) => {
        return axios.request<{ message: string }>({
            method: "GET",
            url: URL_API + "invites/" + uuid,
        });
    };

    static getSpotifyConnectLoginUrl() {
        const accessToken = API.getInstance().accessToken;
        return `${URL}login/spotify?token=${accessToken}`;
    }

    static onDisconnectSpotify() {
        return axios.post(URL_API + "user/disconnect/spotify", null, {
            headers: API.getInstance().getHeaders(),
        });
    }

    static onLogout = () => {
        return axios.request<APIMsg>({
            method: "POST",
            url: URL_API + "logout",
            headers: API.getInstance().getHeaders(),
        });
    };

    static onPutMe = (me: any) => {
        // const bodyFormData = new FormData();
        // for (const [key, value] of Object.entries(me)) {
        //     bodyFormData.append(key, value as string | Blob);
        // }
        return axios.request<APIData<User>>({
            method: "PUT",
            url: URL_API + "me",
            data: me,
            headers: API.getInstance().getHeaders(),
        });
    };

    static onGetMe = () => {
        return axios.request<APIData<User>>({
            method: "GET",
            url: URL_API + "me",
            headers: API.getInstance().getHeaders(),
        });
    };

    static onSetOnline = () => {
        return axios.request<void>({
            method: "POST",
            url: URL_API + "user/online",
            headers: API.getInstance().getHeaders(),
        });
    };

    static onSetOffline = () => {
        return axios.request<void>({
            method: "POST",
            url: URL_API + "user/offline",
            headers: API.getInstance().getHeaders(),
        });
    };

    static onPutPreferences = (onboarding: Onboarding) => {
        const obj = {
            name: onboarding.name,
            genres:
                onboarding?.genres?.map((obj) => {
                    return obj.id;
                }) || [],

            artists:
                onboarding?.artists?.map((obj) => {
                    return obj.id;
                }) || [],
            artist_types:
                onboarding?.artist_types?.map((obj) => {
                    return obj.id;
                }) || [],
        };

        return axios.request<APIData<User>>({
            method: "PUT",
            url: URL_API + "me",
            headers: API.getInstance().getHeaders(),
            data: JSON.stringify(obj),
        });
    };

    static onForgotPw = (email: string) => {
        return axios.request<APIMsg>({
            method: "POST",
            url: URL_API + "forgot-password",
            data: { email },
        });
    };

    static onResetPassword = (
        uuid: string,
        email: string,
        password: string
    ) => {
        return axios.request<APIMsg>({
            method: "POST",
            url: URL_API + "reset-password",
            data: {
                token: uuid,
                email: email,
                password: password,
                password_confirmation: password,
            },
        });
    };

    static onGetBlueprints = () => {
        return axios.request<APIData<Blueprint>>({
            method: "GET",
            url: URL_API + "blueprints",
        });
    };

    static onGetOnboardingData = () => {
        return axios.request<APIData<OnboardingData>>({
            method: "GET",
            url: URL_API + "onboarding",
            headers: API.getInstance().getHeaders(),
        });
    };

    /**
     * Unsplash
     */

    static onGetUnsplashImages = (page: number) => {
        return axios.request<any>({
            method: "GET",
            url:
                "https://api.unsplash.com/photos?client_id=" +
                UNSPLASH_KEY +
                "&per_page=20&page=" +
                page,
        });
    };

    static onGetUnsplashImagesSearch = (page: number, searchParam: string) => {
        return axios.request<any>({
            method: "GET",
            url:
                "https://api.unsplash.com/search/photos?query=" +
                searchParam +
                "&client_id=" +
                UNSPLASH_KEY +
                "&per_page=20&page=" +
                page,
        });
    };
    static onGetUnsplashDownloadImage = (downloadLink: string) => {
        return axios.request<any>({
            method: "GET",
            url: downloadLink + "&client_id=" + UNSPLASH_KEY,
        });
    };

    /**
     * Profiles
     */
    static onGetProfile = (uuid: string) => {
        return axios.request<APIData<User>>({
            method: "GET",
            url: URL_API + "users/" + uuid,
            headers: API.getInstance().getHeaders(),
        });
    };
    static onGetTracks = (uuid: string, page: number) => {
        return axios.get<APITableData<Track>>(
            URL_API + "users/" + uuid + "/tracks?page=" + page,
            {
                headers: API.getInstance().getHeaders(),
            }
        );
    };
    static onGetFavourites = (uuid: string, page: number) => {
        return axios.get<APITableData<Track>>(
            URL_API + "tracks/favourite?page=" + page,
            {
                headers: API.getInstance().getHeaders(),
            }
        );
    };

    /**
     * AUDIO
     */
    static onGetPlaylist = (page: number, filter: string) => {
        const options: any = {
            headers: API.getInstance().getHeaders(),
        };
        return axios.get<APITableData<Track>>(
            URL_API +
                "tracks/discover?page=" +
                page +
                (filter != "" ? "&filter[completion_stage_id]=" + filter : ""),
            options
        );
    };
    static onPostListeningSession = (lsObj: ListeningSession) => {
        return axios.request<APIData<ListeningSession>>({
            method: "POST",
            url: URL_API + "tracks/" + lsObj.track_id + "/listening-sessions",
            data: lsObj,
            headers: API.getInstance().getHeaders(),
        });
    };
    static onGetListeningSession = (trackId: string) => {
        return axios.request<APITableData<ListeningSession>>({
            method: "GET",
            url: URL_API + "tracks/" + trackId + "/listening-sessions/",
            headers: API.getInstance().getHeaders(),
        });
    };

    /**
     * SEARCH
     */
    static onGetSearch = (searchParam: string, page: number) => {
        return axios.request<APITableData<Track>>({
            method: "POST",
            url: URL_API + "tracks/search/",
            data: { query: searchParam, page: page },
            headers: API.getInstance().getHeaders(),
        });
    };

    /**
     * ASSIST
     */
    static onGetAssistList = (page: number) => {
        return axios.request<APITableData<Track>>({
            method: "GET",
            url: URL_API + "tracks/assist?limit=30&page=" + page,
            headers: API.getInstance().getHeaders(),
        });
    };

    static onPostDeclineAssist = (track_id: string) => {
        console.log("data", track_id);
        return new Promise<any>((resolve) => {
            setTimeout(() => resolve({ msg: "done" }), 1000);
        });
    };

    /**
     * COLLABORATIONS
     */
    static onGetCollaborations(
        page = 1,
        type = ["received"],
        status = ["new"],
        original_track_id = ""
    ) {
        // let paramsString = `?`;
        let paramsString = `?page=${page}`;

        if (type !== [""]) {
            paramsString = paramsString + `&filter[type]=`;
            for (let i = 0; i < type.length; i++) {
                paramsString = paramsString + `${type[i]}`;
                if (i + 1 !== type.length) {
                    paramsString = paramsString + ",";
                }
            }
        }
        if (status !== [""]) {
            paramsString = paramsString + `&filter[status]=`;
            for (let i = 0; i < status.length; i++) {
                paramsString = paramsString + `${status[i]}`;
                if (i + 1 !== status.length) {
                    paramsString = paramsString + ",";
                }
            }
        }

        if (original_track_id !== "")
            paramsString =
                paramsString +
                "&filter[original_track_id]=" +
                original_track_id;

        return axios.get<APIData<Collaboration[]>>(
            URL_API + "collaborations" + paramsString,
            {
                headers: API.getInstance().getHeaders(),
            }
        );
    }
    /** TODO WHEN BACKEND IS DONE MAKING MULTIPLE SELECTS USE THIS ONE */
    static onGetCollaborationsCorrect(page = 1, type = [""], status = "new") {
        const params: { page: number; type?: string[]; status?: string } = {
            page,
        };
        if (type !== [""]) params.type = type;
        if (status !== "") params.status = status;
        return axios.get<APIData<ReadonlyArray<Collaboration>>>(
            URL_API + "collaborations",
            {
                params: params,
                headers: API.getInstance().getHeaders(),
            }
        );
    }

    static onGetCollaborationById(collaborationId: string) {
        return axios.get<APIData<Collaboration>>(
            `${URL_API}collaborations/${collaborationId}`,
            {
                headers: API.getInstance().getHeaders(),
            }
        );
    }

    static onGetCollaborationMessages(
        collaborationId: string,
        cursor?: string
    ) {
        return axios.get<APIData<Message[]>>(
            `${URL_API}collaborations/${collaborationId}/messages`,
            {
                params: {
                    cursor,
                },
                headers: API.getInstance().getHeaders(),
            }
        );
    }

    static onPostMessage(collaborationId: string, message: string) {
        return axios.post<APIData<Message>>(
            `${URL_API}collaborations/${collaborationId}/messages`,
            {
                message,
            },
            {
                headers: API.getInstance().getHeaders(),
            }
        );
    }
    static onDeleteMessage(collaborationId: string, messageId: string) {
        return axios.delete<any>(
            `${URL_API}collaborations/${collaborationId}/messages/${messageId}`,
            {
                headers: API.getInstance().getHeaders(),
            }
        );
    }

    static async onPostFileMessage(collaborationId: string, file: File) {
        const presignedUrl = await API.onPostAssignedURL().then(
            (response) => response.data.data
        );

        await API.onPostUploadToPresignedURL(presignedUrl, file);

        return axios.post<APIData<any>>(
            `${URL_API}collaborations/${collaborationId}/files`,
            {
                filename: file.name,
                presigned_uuid: presignedUrl.uuid,
                mime_type: file.type,
            },
            {
                headers: API.getInstance().getHeaders(),
            }
        );
    }

    static async onGetCollaborationFiles(collaborationId: string) {
        return axios.get<APIData<any>>(
            `${URL_API}collaborations/${collaborationId}/files`,

            {
                headers: API.getInstance().getHeaders(),
            }
        );
    }

    /**
     * COLLABORATION REQUESTS
     */
    static onPostCollab = (
        msg: string,
        trackId: string,
        newTrackId: string | null | undefined,
        mimeType?: string | undefined,
        filename?: string | undefined
    ) => {
        const obj: {
            message: string;
            original_track_id: string;
            filename?: string;
            presigned_uuid?: string;
            mime_type?: string;
        } = {
            message: msg,
            original_track_id: trackId,
        };
        if (newTrackId !== null && newTrackId !== undefined) {
            obj.presigned_uuid = newTrackId;
        }
        if (filename !== null && filename !== undefined) {
            obj.filename = filename;
        }
        if (mimeType !== null && mimeType !== undefined) {
            obj.mime_type = mimeType;
        }

        return axios.request<APIMsg>({
            method: "POST",
            data: obj,
            url: URL_API + "collaborations",
            headers: API.getInstance().getHeaders(),
        });
    };

    static onPostCollabTypingRequest(collabId: string) {
        return axios.request<APIMsg>({
            method: "POST",
            data: {},
            url: URL_API + "collaborations/" + collabId + "/typing",
            headers: API.getInstance().getHeaders(),
        });
    }
    static onGetCollaborationRequests(type: "sent" | "received", page = 1) {
        return axios.get<APIData<Collaboration>>(URL_API + "collaborations", {
            params: {
                page,
                type,
            },
            headers: API.getInstance().getHeaders(),
        });
    }

    static onAcceptCollaborationRequestById(requestId: string) {
        return axios.put<APIData<Collaboration>>(
            `${URL_API}collaborations/${requestId}/accept`,
            {
                accepted: true,
            },
            {
                headers: API.getInstance().getHeaders(),
            }
        );
    }

    static onDeclineCollaborationRequestById(requestId: string) {
        return axios.put<APIData<Collaboration>>(
            `${URL_API}collaborations/${requestId}/decline`,
            {
                accepted: false,
            },
            {
                headers: API.getInstance().getHeaders(),
            }
        );
    }

    static onCancelCollaborationRequestById(requestId: string) {
        return axios.put<APIData<Collaboration>>(
            `${URL_API}collaborations/${requestId}/cancel`,
            {},
            {
                headers: API.getInstance().getHeaders(),
            }
        );
    }

    static onPostEndCollab(requestId: string) {
        return axios.put<APIData<Collaboration>>(
            `${URL_API}collaborations/${requestId}/end`,
            {},
            {
                headers: API.getInstance().getHeaders(),
            }
        );
    }

    static onPostMuteUser(userId: string) {
        return axios.request<APIData<PresignedUrl>>({
            method: "POST",
            url: URL_API + "muted-users",
            data: { muted_id: userId },
            headers: API.getInstance().getHeaders(),
        });
    }

    /**
     * SEND INVITE
     */
    static onSendInvite(email: string) {
        return axios.post<{ message: string; remaining_invites: number }>(
            `${URL_API}invites`,
            { email: email },
            {
                headers: API.getInstance().getHeaders(),
            }
        );
    }

    /**
     * UPLOAD SONG
     */
    static onPostAssignedURL = () => {
        return axios.request<APIData<PresignedUrl>>({
            method: "POST",
            url: URL_API + "signed-storage-url",
            data: {},
            headers: API.getInstance().getHeaders(),
        });
    };

    static onPostUploadToPresignedURL = async (
        presignedURL: PresignedUrl,
        file: File
    ) => {
        console.log("file", file);
        return axios.put<APIData<PresignedUrl>>(presignedURL.url, file, {
            headers: {
                "Content-Type": file.type,
            },
        });
    };

    static onPostTrack = (
        uuid: string,
        track: File,
        cutTime: number,
        trackName: string,
        genres: string[],
        artists: string[],
        complete: string,
        lyrics: string,
        story: string
    ) => {
        const obj: any = {
            presigned_uuid: uuid,
            filename: track.name,
            mime_type: track.type,
            title: trackName,
            preview_offset_milliseconds: Math.round(cutTime * 1000),
            artist_types: artists,
            completion_stage_id: complete,
            genres: genres,
        };

        if (lyrics) {
            obj["lyrics"] = lyrics;
        }
        if (story) {
            obj["story"] = story;
        }

        return axios.request<APIData<PresignedUrl>>({
            method: "POST",
            url: URL_API + "tracks",
            data: obj,
            headers: API.getInstance().getHeaders(),
        });
    };
    static onPutTrack = (
        uuid: string,
        trackName: string,
        genres: string[],
        artists: string[],
        lyrics: string,
        story: string,
        completion_stage_id: string
    ) => {
        const obj: {
            title: string;
            artist_types: string[];
            genres: string[];
            lyrics?: string;
            story?: string;
            completion_stage_id: string;
        } = {
            title: trackName,
            artist_types: artists,
            genres: genres,
            completion_stage_id: completion_stage_id,
        };

        if (lyrics) {
            obj["lyrics"] = lyrics;
        }
        if (story) {
            obj["story"] = story;
        }

        return axios.request<APIData<PresignedUrl>>({
            method: "PUT",
            url: URL_API + "tracks/" + uuid,
            data: obj,
            headers: API.getInstance().getHeaders(),
        });
    };
    static onDeleteTrack = (uuid: string) => {
        return axios.request<APIData<PresignedUrl>>({
            method: "DELETE",
            url: URL_API + "tracks/" + uuid,
            headers: API.getInstance().getHeaders(),
        });
    };
}

export default API;
