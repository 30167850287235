import { ReactNode, useContext, useEffect } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage.hook";
import Constants from "../lib/Constants";
import { AppLifeContext } from "../lib/Context";

type Props = {
    children?: ReactNode;
};

const Layout = ({ children }: Props) => {
    //
    const appCtx = useContext(AppLifeContext);
    const [darkmodeLS] = useLocalStorage(Constants.LOCALSTORAGE_DARKMODE_KEY);

    useEffect(() => {
        appCtx.setDarkmode(darkmodeLS === "true" ? true : false);
    }, [darkmodeLS]);

    useEffect(() => {
        console.log("🚀 Version: ", Constants.VERSION);
    }, []);

    return (
        <div
            className={
                "w-full h-full relative " + (appCtx.darkmode ? "dark" : "")
            }
        >
            {children}
        </div>
    );
};

export default Layout;
